$(document).ready(function() {
    var selectAllChecked = false;
    var deleteButton = document.getElementById("delete-selected");
    var show = document.getElementById("pagy-count");
    var selectCheckboxes = document.getElementsByClassName("record-checkbox");

    $("#select-all").change(function() {
        if ($(this).is(":checked")) {
            $(".record-checkbox").prop("checked", true);
            selectAllChecked = true;
            deleteButton.removeAttribute("hidden");
            show.hidden = true;
        } else {
            $(".record-checkbox").prop("checked", false);
            selectAllChecked = false;
            deleteButton.setAttribute("hidden", "true");
            show.removeAttribute("hidden");
        }
    });

    $(".record-checkbox").change(function() {
        var checkedCheckboxes = $(".record-checkbox:checked").length;

        if (checkedCheckboxes > 0) {
            deleteButton.removeAttribute("hidden");
            show.setAttribute("hidden", "true");
        } else {
            deleteButton.setAttribute("hidden", "true");
            show.removeAttribute("hidden");
        }

        if (!$(this).is(":checked")) {
            $("#select-all").prop("checked", false);
            selectAllChecked = false;
        }

        if ($(".record-checkbox:checked").length === $(".record-checkbox").length) {
            $("#select-all").prop("checked", true);
            selectAllChecked = true;
        }
    });

    $("#delete-selected").click(function() {
        var dataId = $(this).attr("data-id");
        deleteRecords(dataId);
    });

    function deleteRecords(dataId) {
        var selectedIds = [];
        if (selectAllChecked) {
            var confirmation = confirm("すべての予約を削除します。この操作は元に戻せません?");
            if (confirmation) {
                $('.loading').removeClass("d-none");
                $('.layout-fixed').css('overflow', 'hidden');

                $.ajax({
                    url: "/bookings/delete_all",
                    method: "POST",
                    dataType: "json",
                    data: { data_id: dataId },
                    success: function(response) {
                        if (response.success) {
                            window.location.href = "/bookings";
                            $(".record-checkbox:checked").prop("checked", false);
                            $('.layout-fixed').css('overflow', 'auto');
                            $('.loading').addClass("d-none");
                        } else {
                            window.location.href = "/bookings";
                        }
                    },
                    error: function(xhr) {
                        window.location.href = "/bookings";
                    },
                });
            }
        } else {
            $(".record-checkbox:checked").each(function() {
                selectedIds.push($(this).val());
            });
            if (selectedIds.length > 0) {
                var confirmation = confirm("選択した予約を削除します。この操作は元に戻せません?");
                if (confirmation) {
                    $('.loading').removeClass("d-none");
                    $('.layout-fixed').css('overflow', 'hidden');

                    $.ajax({
                        url: "/bookings",
                        method: "DELETE",
                        data: { selected_ids: selectedIds.join(",") },
                        dataType: "json",
                        success: function(response) {
                            if (response.success) {
                                window.location.href = "/bookings";
                                $(".record-checkbox:checked").prop("checked", false);
                                $('.layout-fixed').css('overflow', 'auto');
                                $('.loading').addClass("d-none");
                            } else {
                                window.location.href = "/bookings";
                            }
                        },
                        error: function(xhr) {
                            window.location.href = "/bookings";
                        },
                    });
                }
            }
        }
    }

    for (var i = 0; i < selectCheckboxes.length; i++) {
        selectCheckboxes[i].addEventListener("click", function() {
            var checkedCheckboxes = document.querySelectorAll('.record-checkbox:checked');
            if (checkedCheckboxes.length > 0) {
                deleteButton.removeAttribute("hidden");
                show.setAttribute("hidden", "true");
            } else {
                deleteButton.setAttribute("hidden", "true");
                show.removeAttribute("hidden");
            }
        });
    }
});
