$(() => {
  $('#form_bills').on('change', '.service-type-select', e => {
    const $this = $(e.currentTarget);
    // bill[index][category-id] => [bill, index, category-id]
    var index = $this.attr('name').replaceAll(']', '').split('[').at(1);
    const category_id = $this.val();

    $.ajax({
      url: '/xhr/bills/select',
      type: 'POST',
      data: {
        index: index,
        category_id: category_id
      }
    });
  });

  $('.confirm-remove-product').on('click', e => {
    if (confirm('削除してもいいですか？') === true) {
      $this = $(e.currentTarget);
      $tr = $this.closest('tr');
      product_id = $tr.find('.product-name').data('id');
      $form = $('#form_confirm_bill');
      $form.append(`<input type="hidden" name="remove_products[]", value="${product_id}">`);
      $tr.remove();
      App.bills.reload_index();
    }
  });

  $('#block_product').on('click', '.remove-product', e => {
    $this = $(e.currentTarget);
    if ($('#block_product .div-product').length === 1) {
      alert('少なくとも 1 つの製品をインポートする必要があります。');
    } else {
      $this.closest('.div-product').remove();
    }
  });

  if ($('#chart_bill').length > 0) {
    const labels = $('#labels_chart').val().split('===');
    const data_input = $('#data_input_chart').val().split(' ');
    const data_output = $('#data_output_chart').val().split(' ');
    const data_revenue = $('#data_revenue_chart').val().split(' ');
    const category_name = $('#category_name_chart').val();
    App.bills.chart(labels, data_input, data_output, data_revenue, category_name);
  }
});

App.bills = {
  reload_index: () => {
    $('.index').each((index, element) => {
      $(element).html(`${index + 1}`);
    });
  },

  chart: (labels, data_input, data_output, data_revenue, category_name) => {
    var size = 0;

    if (labels.length > 15) {
      size = "flex"
    } else if (labels.length > 10 && labels.length <= 15) {
      size = 20
    } else if (labels.length <= 10) {
      size = 30
    }

    var data = {
      labels: labels,
      datasets: [
        {
          type: 'line',
          label: '壳上金额',
          data: data_revenue,
          borderColor: '#FBBC04',
          backgroundColor: '#FBBC04',
          yAxisID: 'y1',
        },
        {
          type: 'bar',
          label: '入荷数',
          data: data_input,
          borderColor: '#4285F4',
          backgroundColor: '#4285F4',
          barThickness: size,
          yAxisID: 'y',
        },
        {
          type: 'bar',
          label: '出荷数',
          data: data_output,
          borderColor: '#34A853',
          backgroundColor: '#34A853',
          barThickness: size,
          yAxisID: 'y',
        }
      ]
    };
    category_name = category_name === '' ? '' : `（${category_name}）`;
    var ctx = document.getElementById('chart_bill');
    new Chart(ctx, {
      data: data,
      options: {
        responsive: true,
        interaction: {
          mode: 'index',
          intersect: false,
        },
        stacked: false,
        plugins: {
          title: {
            display: true,
            text: `入荷数と出荷数${category_name}`,
            font: {size: 25},
            padding: 10
          },
          legend: {
            position: 'bottom',
            labels: {
              filter: (e) => {
                if (e.text === '壳上金额') {
                  e.lineWidth = 0
                }
                return true;
              }
            }
          }
        },
        scales: {
          x: {
            stacked: true,
            grid: {
              display: false
            },
          },
          y: {
            type: 'linear',
            display: true,
            position: 'left',
            grid: {
              display: false
            },
            title: {
              display: true,
              text: '入出荷数［個］'
            }
          },
          y1: {
            type: 'linear',
            display: true,
            position: 'right',
            grid: {
              display: false
            },
            title: {
              display: true,
              text: '売上金額'
            }
          },
        }
      },
    });
  },
};
