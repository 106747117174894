$(document).ready(function() {
    var form = $("#search-form");
    var submitBtn = form.find(":submit");
    var inputs = form.find(":input");

    submitBtn.prop("disabled", true);

    function toggleSubmitBtn() {
      var filledInputs = inputs.filter(function() {
        return $(this).val() != "";
      });
      if (filledInputs.length > 4) {
        submitBtn.prop("disabled", false);
      } else {
        submitBtn.prop("disabled", true);
      }
    }

    inputs.on("input", toggleSubmitBtn);

    input_staff = $('input[name="staff"]');
    if (input_staff) {
      input_staff.on('change', e => {
        $this = $(e.currentTarget);
        if ($this.val() === 'item') {
          $('#target_staff_id').css('display', 'block');
        } else {
          $('#target_staff_id').css('display', 'none');
        }
        $('#staff-error-message').css('display', 'none');
      });

      if ($('input[name="staff"]:checked').val() === 'item') {
        $('#target_staff_id').css('display', 'block');
      } else {
        $('#target_staff_id').css('display', 'none');
      }
    }
  });