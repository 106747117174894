/* eslint no-console:0 */
import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";
import "jquery";
import "popper.js";
import "bootstrap";
import "stylesheets/responsive";
import "stylesheets/common";
import "stylesheets/hover";
import "bootstrap-icons/font/bootstrap-icons.css";
import "packs/common";
import "packs/charts";
import "packs/flash";
import "packs/target";
import "packs/user";
import "packs/booking";
import "packs/product";
import "packs/bills";
import "packs/select2";
import "packs/charts";
import "packs/totals";
import "packs/delete_booking";
import "packs/messages_error_target";
import "packs/messages_error_product";
require("@nathanvda/cocoon");
require("admin-lte");
import 'packs/menu'
import "stylesheets/base";
import "../stylesheets/application";
import "@fortawesome/fontawesome-free/js/all";
import Chart from "chart.js/auto";
import * as luxon from "chartjs-adapter-luxon";
import * as finChart from "chartjs-chart-financial";

// Register the plugin's custom controllers
Chart.register(finChart.CandlestickController, finChart.CandlestickElement);

// Ensure the Chart class is loaded in the global context
window.Chart = Chart;

// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip();
});

Rails.start();
ActiveStorage.start();
