import Chart from 'chart.js/auto'
import * as finChart from 'chartjs-chart-financial'

// Register the plugin's custom controllers
Chart.register(finChart.CandlestickController, finChart.CandlestickElement)

// Ensure the Chart class is loaded in the global context
window.Chart = Chart

App.chart_js = {
  defaultLegendClickHandler: Chart.defaults.plugins.legend.onClick
}
