$(() => {
  if ($('#chart').length > 0) {
    var labels = App.totals.get_labels();
    const type = App.totals.get_type();
    if (['8', '9'].includes(type)) {
      labels = App.totals.get_labels('===');
    }
    const parent_data = App.totals.get_parent_data();
    const child_data = App.totals.get_child_data();
    const line_datas = App.totals.get_line_data(labels);
    const child_line_datas = App.totals.get_child_line_data(labels);
    App.totals.chart('#chart', labels, parent_data, child_data, line_datas, child_line_datas, type);
  }
});

App.totals = {
  CUMULATIVE: ['2','7'],
  TYPE: {
    '1': {
      PARENT: '売上[店舖]',
      CHILD: '売上[個人]',
      LINE: '目標売上[店舖]',
      CHILD_LINE: '目標売上[個人]',
      NAME: '売上',
      UNIT: '売上（千円）'
    },
    '2': {
      PARENT:'累計売上[店舗]',
      CHILD:'累計売上[個人]',
      LINE: '目標累計売上[店舖]',
      CHILD_LINE: '目標累計売上[個人]',
      NAME: '累計売上',
      UNIT: '売上（千円）'
    },
    '3': {
      PARENT:'店舗',
      CHILD:'個人',
      NAME: '曜日毎の売上',
      UNIT: '販売数［個］'
    },
    '4': {
      PARENT: '新規人数[店铺]',
      CHILD: '新規人数[個人]',
      NAME: '新規人数',
      UNIT: '人数(人)'
    },
    '5': {
      PARENT: '既存人数[店铺]',
      CHILD: '既存人数[個人]',
      LINE: '目標既存人数[店铺]',
      CHILD_LINE: '目標既存人数[個人]',
      NAME: '人数目標',
      UNIT: '人数(人)'
    },
    '6': {
      PARENT: '次回予約人数[店铺]',
      CHILD: '次回予約人数[個人]',
      LINE: '目標次回予約人数[店铺]',
      CHILD_LINE: '目標次回予約人数[個人]',
      NAME: '次回予約人数',
      UNIT: '人数(人)'
    },
    '7': {
      PARENT: '累計次回予約人数[店铺]',
      CHILD: '累計次回予約人数[個人]',
      LINE: '目標累計次回予約人数[店舖]',
      CHILD_LINE: '目標累計次回予約人数[個人]',
      NAME: '累計次回予約人数',
      UNIT: '人数(人)'
    },
    '8': {
      PARENT: '店販販売数[店铺]',
      CHILD: '店販販売数[個人]',
      NAME: '店販販売数',
      UNIT: '販売数［個］'
    },
    '9': {
      PARENT: '店販販売金額[店铺]',
      CHILD: '店販販売金額[個人]',
      NAME: '店販販売金額',
      UNIT: '販売金額[円]'
    }
  },
  chart: (element, labels, parent_data, child_data, line_data, child_line_datas, type) => {
    var parent_data_origin = $('#parent_data_chart').val().split(' ');
    var line_data_origin = App.totals.get_line_data(labels, false);
    if (App.totals.CUMULATIVE.includes(type)) {
      parent_data = App.totals.cumulative(parent_data);
      child_data = App.totals.cumulative(child_data);
      parent_data_origin = App.totals.cumulative(parent_data_origin);
      line_data = App.totals.cumulative(line_data);
      child_line_datas = App.totals.cumulative(child_line_datas);
      line_data_origin = App.totals.cumulative(line_data_origin);
    }

    var size = 0

    if (labels.length > 15) {
      size = "flex"
    } else if (labels.length > 10 && labels.length <= 15) {
      size = 20
    } else if (labels.length <= 10) {
      size = 30
    }

    var datasets = [
      {
        label: App.totals.TYPE[type].CHILD,
        backgroundColor: '#4285F4',
        data: child_data,
        barThickness: size,
        categoryPercentage: 0.7,
      }, {
        label: App.totals.TYPE[type].PARENT,
        backgroundColor: '#34A853',
        data: parent_data,
        barThickness: size,
        categoryPercentage: 0.7,
      }
    ]

    if (line_data.length > 0) {
      datasets.unshift({
        type: 'line',
        label: App.totals.TYPE[type].LINE,
        borderColor: '#FBBC04',
        backgroundColor: '#FBBC04',
        borderWidth: 6,
        pointStyle: 'circle',
        pointBorderWidth: 6,
        data: line_data
      });
    }

    if (child_line_datas.length > 0) {
      datasets.unshift({
        type: 'line',
        label: App.totals.TYPE[type].CHILD_LINE,
        borderColor: '#FFC0CB',
        backgroundColor: '#FFC0CB',
        borderWidth: 6,
        pointStyle: 'circle',
        pointBorderWidth: 6,
        data: child_line_datas
      });
    }

    var name_chart = '';
    if (['8', '9'].includes(type) && $('select[name="q[product_category_id_eq]"]').val() !== '') {
      category_name = $('select[name="q[product_category_id_eq]"] option:selected').text();
      name_chart = `入荷数と出荷数（${category_name}）`;
    } else {
      name_chart = App.totals.TYPE[type].NAME;
    }

    const cfg = {
      type: 'bar',
      data: {
        labels: labels,
        datasets: datasets
      },
      options: {
        scales: {
          x: {
            stacked: true,
            grid: {
              display: false
            },
          },
          y: {
            stacked: true,
            grid: {
              display: false
            },
            title: {
              display: true,
              text: App.totals.TYPE[type].UNIT
            }
          }
        },
        plugins: {
          legend: {
            onClick: (e, legendItem, legend) => {
              myChart = legend.chart;
              if (legendItem.text === App.totals.TYPE[type].CHILD) {
                // hidden = false => hide child or opposite
                if (!legendItem.hidden) {
                  myChart.data.datasets.at(-1).data = parent_data_origin;
                } else {
                  myChart.data.datasets.at(-1).data = parent_data;
                }
              }

              if (legendItem.text === App.totals.TYPE[type].CHILD_LINE && line_data.length > 0) {
                // hidden = false => hide child or opposite
                if (!legendItem.hidden) {
                  myChart.data.datasets.at(-3).data = line_data_origin;
                } else {
                  myChart.data.datasets.at(-3).data = line_data;
                }
              }

              myChart.update();
              App.chart_js.defaultLegendClickHandler(e, legendItem, legend);
            },
            position: 'bottom',
            labels: {
              filter: (e) => {
                if (e.text === App.totals.TYPE[type].LINE || e.text === App.totals.TYPE[type].CHILD_LINE) {
                  e.lineWidth = 0
                }
                return true;
              }
            }
          },
          title: {
            display: true,
            text: name_chart,
            font: {size: 25},
            padding: 10
          }
        }
      }
    };

    canvas = $(element).get(0);
    ctx = canvas.getContext('2d');
    new Chart(ctx, cfg);
  },

  get_labels: (separator) => {
    separator = separator ? separator : ' ';
    if ($('#labels_chart').length > 0) {
      return $('#labels_chart').val().split(separator);
    }
  },

  get_parent_data: () => {
    if ($('#parent_data_chart').length > 0) {
      result = [];
      child_data = App.totals.get_child_data();
      parent_data = $('#parent_data_chart').val().split(' ');
      $.each(parent_data, (index, data) => {
        child_data_nan = child_data.at(index) === '' ? '0' : child_data.at(index)
        result.push((parseInt(data) - parseInt(child_data_nan)).toString());
      });

      return result;
    }
  },

  get_child_data: () => {
    if ($('#child_data_chart').length > 0) {
      return $('#child_data_chart').val().split(' ');
    }
  },

  get_type: () => {
    if ($('#type').length > 0) {
      return $('#type').val();
    }
  },

  get_line_data: (labels, child_flg = true) => {
    if ($('#line_data_chart').length > 0) {
      line_datas = [];
      line_data = $('#line_data_chart').val();

      if (line_data === '') return [];

      child_line_datas = App.totals.get_child_line_data(labels);

      if (line_data.split(" ").length > 1) {
        line_datas = line_data.split(" ");
      } else {
        $.each(labels, (_index, _label) => {
          line_datas.push(line_data);
        });
      }

      if (!child_flg) return line_datas;
      if (child_line_datas.length === 0) return line_datas;

      result = [];
      line_data_uniq = App.totals.get_uniq(line_datas);
      if (line_data_uniq.length === 1 && line_data_uniq[0] === '0') return [];

      $.each(line_datas, (index, line_item) => {
        result[index] = line_item - child_line_datas[index];
      });

      return result;
    }
  },

  get_uniq: arr_input => {
    onlyUnique = (value, index, array) => {
      return array.indexOf(value) === index;
    }

    return arr_input.filter(onlyUnique);
  },

  get_child_line_data: labels => {
    if ($('#child_line_data_chart').length > 0) {
      child_line_datas = [];
      child_line_data = $('#child_line_data_chart').val();

      if (child_line_data === '') return [];

      if (child_line_data.split(" ").length > 1) {
        child_line_datas = child_line_data.split(" ");
      } else {
        $.each(labels, (_index, _label) => {
          child_line_datas.push(child_line_data);
        });
      }

      child_line_data_uniq = App.totals.get_uniq(child_line_datas);
      if (child_line_data_uniq.length === 1 && parseInt(child_line_data_uniq[0]) === 0) return [];

      return child_line_datas;
    }
  },

  get_output_data: () => {
    if ($('#parent_data_chart').length > 0) {
      return $('#parent_data_chart').val().split(' ');
    }
  },

  cumulative: data => {
    var result = [];
    var sum = 0;
    if (data.constructor.name === 'Array' && data.length > 0) {
      $.each(data, (index, item) => {
        item_nan = item === '' ? '0' : item;
        sum += parseInt(item_nan);
        result.push(sum.toString());
      })
    }

    return result;
  },
};
