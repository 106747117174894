$(() => {
  $(".upload_file").on("change", (e) => {
    var file_name = e.target.files[0].name
    if(/\.(csv)/i.test(file_name)) {
      $(".upload_icon").addClass("d-none")
      $(".i_success").removeClass("d-none")
      $(".upload_text").text(file_name)
      $("#import-button").removeClass("disabled")
    } else {
      $(".upload_icon").addClass("d-none")
      $(".i_error").removeClass("d-none")
      $(".upload_text").text("ファイル形式が正しくありません")
      $("#import-button").addClass("disabled")
    }
  })

  function messager_csv(data) {
    $('.layout-fixed').css('overflow','auto');
    $('.loading').addClass("d-none");
    $("#csv_error").html(`<div class="bg-danger text-light rounded p-3">${data}</div>`)
    setTimeout(() => {
      $("#csv_error").html("");
    }, 3000);
  }

  $(document).on('click', '#import-button', function(event) {
    event.preventDefault();
    $('.loading').removeClass("d-none");
    $('.layout-fixed').css('overflow','hidden');
  
    var form = $(this).closest('form');
    var url = form.attr('action');
    var formData = new FormData(form[0]);
  
    $.ajax({
      url: url,
      type: 'POST',
      data: formData,
      processData: false,
      contentType: false,
      success: function(response) {
        $('.layout-fixed').css('overflow','auto');
        $('.loading').addClass("d-none");
        $('.wrapper').html(response);
      },
      error: function(xhr, status, error) {
        if(xhr.responseText.split(":")[0] == "Encoding"){
          messager_csv("エンコードエラーが発生しました")
        }else{
          messager_csv(`エラーが発生しました`)
        }
      }
    });
  });
})
