document.addEventListener("DOMContentLoaded", function () {
  const productField = document.querySelector(".product-type-field");
  const categoryField = document.querySelector(".category-field");
  const nameField = document.querySelector(".name-field");
  const unitField = document.querySelector(".unit-field");
  const purchaseField = document.querySelector(".purchase-field");
  const priceField = document.querySelector(".price-field");
  const productMessage = document.getElementById("product-error-message");
  const categoryMessage = document.getElementById("category-error-message");
  const nameErrorMessage = document.getElementById("name-error-message");
  const unitErrorMessage = document.getElementById("unit-error-message");
  const purchaseErrorMessage = document.getElementById(
    "purchase-price-error-message"
  );
  const priceErrorMessage = document.getElementById("price-error-message");

  if (nameField) {
    nameField.addEventListener("input", function () {
      nameErrorMessage.style.display =
        nameField.value.trim() === "" ? "block" : "none";
    });
  }

  if (unitField) {
    unitField.addEventListener("input", function () {
      unitErrorMessage.style.display =
        unitField.value.trim() === "" ? "block" : "none";
    });
  }

  if (purchaseField) {
    purchaseField.addEventListener("input", function () {
      purchaseErrorMessage.style.display =
        purchaseField.value.trim() === "" ? "block" : "none";
    });
  }

  if (priceField) {
    priceField.addEventListener("input", function () {
      priceErrorMessage.style.display =
        priceField.value.trim() === "" ? "block" : "none";
    });
  }

  if (categoryField) {
    categoryField.addEventListener("input", function () {
      categoryMessage.style.display =
        categoryField.value.trim() === "" ? "block" : "none";
    });
  }

  if (productField) {
    productField.addEventListener("input", function () {
      productMessage.style.display =
        productField.value.trim() === "" ? "block" : "none";
    });
  }
});
