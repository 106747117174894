$(() => {
  initcheckProductType();

  $("#product_product_type").on("change", function (e) {
    checkProductType();
  });

  $("#q_product_type_eq").on("change", (e) => {
    if (e.target.value == 1) {
      $("#q_price_gteq").attr("name", "q[purchase_price_gteq]");
      $("#q_price_lteq").attr("name", "q[purchase_price_lteq]");
      $(".price_label").text("仕入価格");
    } else {
      $("#q_price_gteq").attr("name", "q[price_gteq]");
      $("#q_price_lteq").attr("name", "q[price_lteq]");
      $(".price_label").text("発注単価");
    }
  });

  $change_product = document.getElementById("changeProduct");
  if ($change_product !== null) {
    $change_product.addEventListener("show.bs.modal", (e) => {
      // set date
      App.products.set_current_date_to_input("#changeProduct #inventory_date");
      $button = $(e.relatedTarget);
      $title = $('#changeProduct #product_name');
      $title.html($button.data('product-name'));
      $title.attr('data-inventory-id', $button.data('inventory-id'));
      $title.data('product-id', $button.data('product-id'));
    });

    $change_product.addEventListener("hide.bs.modal", (e) => {
      // clear input
      $(e.currentTarget).find('.inventories_input').val('');
      $(e.currentTarget).find('select').val('');
    });
  }

  $("#changeProduct").on("click", "#export_product", (e) => {
    var error = App.products.validate_modal_export_product();

    if (error === "") {
      var inventory_id = $("#changeProduct #product_name").data("inventory-id");
      var product_id = $("#changeProduct #product_name").data("product-id");
      $("#changeProduct #id").val(inventory_id);
      $("#changeProduct #product_id").val(product_id);
      $("#form_export_product").submit();
    } else {
      $("#changeProduct #error_message").html(error).show();
      setTimeout(function () {
        $("#changeProduct #error_message").html("").hide();
      }, 3000);
    }
  });

  function checkProductType() {
    const value = $("#product_product_type").val();
    $("#input-1").val("");
    $("#input-2").val("");
    if (value == "店販商品") {
      $("#input-1").prop("readOnly", false);
      $("#input-2").prop("readOnly", false);
      $(".input-2").addClass("d-none");
      $(".input-1").removeClass("d-none");
      $("#input-2").show();
      $(".label-input-2").show();
      $(".price-error-message-not-price").show();
    } else if (value == "非販売品") {
      $("#input-1").prop("readOnly", false);
      $("#input-2").prop("readOnly", true);
      $(".input-1").addClass("d-none");
      $(".input-2").removeClass("d-none");
      $("#input-2").hide();
      $(".label-input-2").hide();
      $(".price-error-message-not-price").hide();
    }
  }

  function initcheckProductType() {
    const value = $("#product_product_type").val();
    if (value == "店販商品") {
      $("#input-1").prop("readOnly", false);
      $("#input-2").prop("readOnly", false);
      $(".input-2").addClass("d-none");
      $(".input-1").removeClass("d-none");
      $("#input-2").show();
      $(".label-input-2").show();
      $(".price-error-message-not-price").show();
    } else if (value == "非販売品") {
      $("#input-1").prop("readOnly", false);
      $("#input-2").prop("readOnly", true);
      $(".input-1").addClass("d-none");
      $(".input-2").removeClass("d-none");
      $("#input-2").hide();
      $(".label-input-2").hide();
      $(".price-error-message-not-price").hide();
    }
  }
});

App.products = {
  key: "product",
  target: {
    add: "add",
    confirm: "confirm",
  },

  validate_modal_export_product: () => {
    var error = [];
    if ($('#changeProduct #staff_id').val() === '') {
      error.push('スタッフをお選びください');
    } else if ($('#changeProduct #quantity').val() === '') {
      error.push('商品の数を入力してください');
    } else {
      $.ajax({
        url: '/inventories/validate_export_product',
        method: 'GET',
        async:false,
        data: {
          quantity: $('#changeProduct #quantity').val(),
          id: $('#changeProduct #product_name').attr('data-inventory-id')
        },
        success: function(response) {
          response ? true : error.push('数量が無効です。')
        },
        error: function(xhr) {
          error.push('エラーが発生しました');
        }
      });
    }
    return error.join('<br>')
  },

  set_current_date_to_input: (element) => {
    var currentDate = new Date();
    var day = ("0" + currentDate.getDate()).slice(-2);
    var month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
    $(element).val(`${currentDate.getFullYear()}-${month}-${day}`);
  },
};
