document.addEventListener("DOMContentLoaded", function () {
  const yearField = document.querySelector(".year-field");
  const monthField = document.querySelector(".month-field");
  const salesTargetFirstField = document.querySelector(
    ".sales_target_first_month_field"
  );
  const salesTargetSecondField = document.querySelector(
    ".sales_target_second_field"
  );
  const venderTargetFirstField = document.querySelector(
    ".vender_target_first_field"
  );
  const venderTargetSecondField = document.querySelector(
    ".vender_target_second_field"
  );
  const peopleTargetFirstField = document.querySelector(
    ".people_target_first_field"
  );
  const peopleTargetSecondField = document.querySelector(
    ".people_target_second_field"
  );
  const reservationTargetFirstField = document.querySelector(
    ".reservation_target_first_field"
  );
  const reservationTargetSecondField = document.querySelector(
    ".reservation_target_second_field"
  );
  const yearMessage = document.getElementById("year-error-message");
  const monthMessage = document.getElementById("month-error-message");
  const salesTargetFirstMessage = document.getElementById(
    "sales_target_first_error_message"
  );
  const salesTargetSecondMessage = document.getElementById(
    "sales_target_second_error_message"
  );
  const venderTargetFirstMessage = document.getElementById(
    "vender_target_first_error_message"
  );
  const venderTargetSecondMessage = document.getElementById(
    "vender_target_second_error_message"
  );
  const peopleTargetFirstMessage = document.getElementById(
    "people_target_first_error_message"
  );
  const peopleTargetSecondMessage = document.getElementById(
    "people_target_second_error_message"
  );
  const reservationTargetFirstMessage = document.getElementById(
    "reservation_target_first_error_message"
  );
  const reservationTargetSecondMessage = document.getElementById(
    "reservation_target_second_error_message"
  );

  if (yearField) {
    yearField.addEventListener("input", function () {
      yearMessage.style.display =
        yearField.value.trim() === "" ? "block" : "none";
    });
  }

  if (monthField) {
    monthField.addEventListener("input", function () {
      monthMessage.style.display =
        monthField.value.trim() === "" ? "block" : "none";
    });
  }

  if (salesTargetFirstField) {
    salesTargetFirstField.addEventListener("input", function () {
      salesTargetFirstMessage.style.display =
        salesTargetFirstField.value.trim() === "" ? "block" : "none";
    });
  }

  if (salesTargetSecondField) {
    salesTargetSecondField.addEventListener("input", function () {
      salesTargetSecondMessage.style.display =
        salesTargetSecondField.value.trim() === "" ? "block" : "none";
    });
  }

  if (venderTargetFirstField) {
    venderTargetFirstField.addEventListener("input", function () {
      venderTargetFirstMessage.style.display =
        venderTargetFirstField.value.trim() === "" ? "block" : "none";
    });
  }

  if (venderTargetSecondField) {
    venderTargetSecondField.addEventListener("input", function () {
      venderTargetSecondMessage.style.display =
        venderTargetSecondField.value.trim() === "" ? "block" : "none";
    });
  }

  if (peopleTargetFirstField) {
    peopleTargetFirstField.addEventListener("input", function () {
      peopleTargetFirstMessage.style.display =
        peopleTargetFirstField.value.trim() === "" ? "block" : "none";
    });
  }

  if (peopleTargetSecondField) {
    peopleTargetSecondField.addEventListener("input", function () {
      peopleTargetSecondMessage.style.display =
        peopleTargetSecondField.value.trim() === "" ? "block" : "none";
    });
  }

  if (reservationTargetFirstField) {
    reservationTargetFirstField.addEventListener("input", function () {
      reservationTargetFirstMessage.style.display =
        reservationTargetFirstField.value.trim() === "" ? "block" : "none";
    });
  }

  if (reservationTargetSecondField) {
    reservationTargetSecondField.addEventListener("input", function () {
      reservationTargetSecondMessage.style.display =
        reservationTargetSecondField.value.trim() === "" ? "block" : "none";
    });
  }
});
