ModalUtils = {
  maxZIndex: function () {
    return Math.max.apply(null, $('.modal:visible').map(function () {
      var z;
      return isNaN(z = parseInt($(this).css("z-index"), 10)) ? 0 : z;
    }));
  }
};

App = {};
